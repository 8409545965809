















































































































import { Component, Prop, Mixins } from 'vue-property-decorator'
import axios from 'axios'
import isEmpty from 'lodash/isEmpty'
import snakeCaseKeys from 'snakecase-keys'
import camelCaseKeys from 'camelcase-keys'

import TablePagination from '@/partials/tables/Pagination.vue'
import Edit16 from '@carbon/icons-vue/es/edit/16'
import TrashCan16 from '@carbon/icons-vue/es/trash-can/16'
import GenericModalDelete from '@/partials/modals/generic/delete.vue'
import FlashMessageMixin from '@/mixins/flashMessageMixin'
import PermissionsMixin from '@/mixins/permissionsMixin'

@Component({
  components: {
    TablePagination,
    GenericModalDelete,
    Edit16,
    TrashCan16
  },
  filters: {
    translateExclusivity (exclusivity: boolean) {
      return exclusivity ? 'Apenas quites' : 'Todos'
    },
    translateAvailbility (availability: 'ongoing' | 'finished') {
      const dict = {
        ongoing: 'À venda',
        finished: 'Encerrado'
      }

      return dict[availability]
    }
  }
})
export default class SubscriptionsTable extends Mixins(FlashMessageMixin, PermissionsMixin) {
  @Prop({ required: true, type: String }) eventId!: string
  subscriptions: Array<any> = []
  subscriptionsTotal = 0
  deleteModalIsOpened = false
  selectedSubscription = {} as any

  created () {
    this.fetchSubscriptions()
  }

  fetchSubscriptions (page = 1, perPage = 20) {
    const perPageSnake = snakeCaseKeys({ perPage })
    axios.get(`/event/${this.eventId}/subscription/type`,
      {
        params: {
          'page[number]': page,
          ...perPageSnake
        }
      })
      .then((response) => {
        this.subscriptions = camelCaseKeys(response.data.data, { deep: true })
        this.subscriptionsTotal = response.data.total
      })
  }

  postSubmit () {
    this.deleteModalIsOpened = false

    const flashMessage = {
      message: 'A Inscrição foi removida com sucesso.',
      isSuccess: true
    }

    this.setFlashMessage(flashMessage)

    this.fetchSubscriptions()
  }

  onPageChange (pagination: Pagination) {
    this.fetchSubscriptions(pagination.page, pagination.length)
  }

  get deleteUrl () {
    return isEmpty(this.selectedSubscription) ? '' : `event/${this.$route.params.id}/subscription/type/${this.selectedSubscription.id}`
  }
}
